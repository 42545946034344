import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, Link, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"recognition"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox7" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			width="100%"
			height="auto"
			padding-top="120px"
			padding-bottom="64px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			padding="90px 16px 64px 16px"
			md-padding="60px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				grid-gap="16px"
				display="flex"
			>
				<Text
					align-self="stretch"
					text-align="center"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					sm-font="600 32px/30px Epilogue"
				>
					Recognition
				</Text>
				<Text
					text-align="center"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
					sm-font="400 17px/26px Epilogue"
				>
					Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent.
			Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
				</Text>
			</Box>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 16px 80px 16px"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			grid-gap="100px"
			display="flex"
			sm-padding="0px 16px 80px 16px"
		>
			<Image
				width="100%"
				src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14:18:50.533Z"
				max-width="1348px"
				height="700px"
				object-fit="cover"
				object-position="50% 0%"
				sm-height="180px"
				md-height="500px"
				srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ddd.png?v=2024-09-06T14%3A18%3A50.533Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
			/>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="50px 16px 0px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			lg-padding="50px 16px 0px 16px"
			lg-display="flex"
			lg-grid-row-gap="32px"
		>
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-align-items="center"
				lg-justify-content="center"
				lg-height="auto"
				lg-width="auto"
				lg-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				grid-column-gap="0px"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					lg-height="250px"
					flex="1 1 0%"
					height="530px"
					width="100%"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18:33:37.851Z"
						lg-width="100%"
						lg-height="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/idk.png?v=2024-08-31T18%3A33%3A37.851Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="block"
					lg-height="auto"
					margin="0px 0px 0px 100px"
					lg-margin="0px 0px 0px 0px"
				>
					<Components.DateTabs tabsCount="5" width="auto" height="100%">
						<Override slot="Tab Content 1">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 15px/24px Epilogue"
							>
								1999 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum
						aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 2">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2000 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 3">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2001 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 4">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2002 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 5">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2003 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Text 1">
							1999
						</Override>
						<Override slot="Tab Text 2">
							2000
						</Override>
						<Override slot="Tab Text 3">
							2001
						</Override>
						<Override slot="Tab Text 4">
							2002
						</Override>
						<Override slot="Tab Text 5">
							2003
						</Override>
						<Override slot="Tab Text" lg-font="700 28px/22px Epilogue" sm-font="700 19px/22px Epilogue" />
						<Override
							slot="box1"
							lg-display="flex"
							lg-flex-direction="row"
							lg-flex-wrap="no-wrap"
							lg-width="100%"
							lg-align-items="center"
							lg-justify-content="space-between"
							lg-grid-column-gap={0}
							lg-height="auto"
						/>
						<Override slot="box" lg-display="flex" lg-grid-row-gap="16px" />
					</Components.DateTabs>
				</Box>
			</Box>
			<Box
				align-self="stretch"
				height="1px"
				background="#B1B1B1"
				margin="0px auto 0px auto"
				max-width="1348px"
			/>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="100px 16px 100px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			lg-padding="40px 16px 0px 16px"
			lg-display="flex"
			lg-grid-row-gap="32px"
		>
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-align-items="center"
				lg-justify-content="center"
				lg-height="auto"
				lg-width="auto"
				lg-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				grid-column-gap="0px"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					lg-height="250px"
					flex="1 1 0%"
					height="530px"
					width="100%"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13:16:00.489Z"
						lg-width="100%"
						lg-height="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="block"
					lg-height="auto"
					margin="0px 100px 0px 0px"
					order="-1"
					lg-margin="0px 0px 0px 0px"
					lg-order="1"
				>
					<Components.DateTabs tabsCount="5" width="auto" height="100%">
						<Override slot="Tab Content 1">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 15px/24px Epilogue"
							>
								1999 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum
						aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 2">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2000 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 3">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2001 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 4">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2002 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 5">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2003 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Text 1">
							2004
						</Override>
						<Override slot="Tab Text 2">
							2005
						</Override>
						<Override slot="Tab Text 3">
							2006
						</Override>
						<Override slot="Tab Text 4">
							2007
						</Override>
						<Override slot="Tab Text 5">
							2008
						</Override>
						<Override slot="Tab Text" lg-font="700 28px/22px Epilogue" sm-font="700 19px/22px Epilogue" />
					</Components.DateTabs>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 190px 100px 190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			lg-padding="50px 16px 24px 16px"
			lg-display="flex"
			lg-grid-row-gap="40px"
		>
			<Box align-self="stretch" height="1px" background="#B1B1B1" />
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-align-items="center"
				lg-justify-content="center"
				lg-height="auto"
				lg-width="auto"
				lg-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				grid-column-gap="0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					lg-height="250px"
					flex="1 1 0%"
					height="530px"
					width="100%"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13:38:42.374Z"
						lg-width="100%"
						lg-height="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="block"
					lg-height="auto"
					margin="0px 0px 0px 100px"
					lg-margin="0px 0px 0px 0px"
				>
					<Components.DateTabs tabsCount="5" width="auto" height="100%">
						<Override slot="Tab Content 1">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 15px/24px Epilogue"
							>
								1999 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum
						aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 2">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2000 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 3">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2001 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 4">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2002 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Content 5">
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0 0 0 0"
								lg-height="auto"
								lg-font="400 17px/26px Epilogue"
							>
								2003 - Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
							</Text>
						</Override>
						<Override slot="Tab Text 1">
							2010
						</Override>
						<Override slot="Tab Text 2">
							2012
						</Override>
						<Override slot="Tab Text 3">
							2018
						</Override>
						<Override slot="Tab Text 4">
							2023
						</Override>
						<Override slot="Tab Text 5">
							2024
						</Override>
						<Override slot="Tab Text" lg-font="700 28px/22px Epilogue" sm-font="700 19px/22px Epilogue" />
					</Components.DateTabs>
				</Box>
			</Box>
			<Box
				align-self="stretch"
				height="1px"
				background="#B1B1B1"
				display="none"
				lg-display="none"
			/>
		</Box>
		<Box
			width="100%"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="inline-flex"
			md-display="flex"
			md-padding="56px 16px 24px 16px"
			md-height="min-content"
			md-grid-row-gap="64px"
			padding="100px 16px 24px 16px"
			background="white"
		>
			{"    "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="80px"
				display="inline-flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-grid-row-gap="24px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					flex="1 1 0"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="inline-flex"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-height="fit-content"
					md-align-items="center"
					md-display="flex"
					md-flex-direction="column"
					md-justify-content="center"
					md-flex-wrap="no-wrap"
					md-grid-column-gap="0px"
					md-grid-row-gap="8px"
					md-max-width="460px"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
					>
						{"        "}
						<Text
							color="#080808"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px"
							md-font="600 28px/32px Epilogue"
							md-width="100%"
							text-fill-color="transparent"
							style={{
								"-webkit-text-fill-color": "transparent"
							}}
							background="linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)"
							background-clip="text"
							max-width="100%"
							width="350px"
						>
							Get in touch...
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Text
						align-self="stretch"
						opacity={0.8}
						color="#080808"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-width="100%"
						md-font="400 17px/26px Epilogue"
					>
						<Strong>
							<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
								info@sphotonix.com
							</Link>
						</Strong>
					</Text>
					{"    "}
				</Box>
				{"    "}
				<Components.MakeForm
					md-width="100%"
					max-width="460px"
					sm-max-width="100%"
					action="https://hook.eu2.make.com/1rbbfu2qo4r932bgoqqy8wdizju2pxtr"
					method="post"
				>
					<Override
						slot="Form"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
					/>
					<Override
						slot="Content"
						display="flex"
						flex-direction="column"
						flex-wrap="no-wrap"
						grid-row-gap="16px"
					/>
					<Override slot="Error Text" />
					<Override slot="Success Text" font="--bodyText17Regular" />
					{"      "}
					<Text
						align-self="stretch"
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-font="400 17px/26px Epilogue"
					>
						Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!
					</Text>
					{"      "}
					<Components.MfInput name="name" type="text" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Name"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					<Components.MfInput name="email" type="email" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Email"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					{"      "}
					<Button
						width="100%"
						padding-left="24px"
						padding-right="24px"
						padding-top="16px"
						padding-bottom="16px"
						background="#EB1E1E"
						border-radius="8px"
						grid-gap="10px"
						color="white"
						font="600 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						text-align="center"
						type="submit"
					>
						Subscibe to Newsletter
					</Button>
					{"    "}
				</Components.MakeForm>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				md-display="flex"
				md-grid-row-gap="32px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					height="1px"
					background="#B1B1B1"
					md-width="100%"
					md-height="1px"
				/>
				<Components.Footer />
				{"    "}{"  "}
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});